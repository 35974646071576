














































































































import Vue from "vue";
import _ from "lodash";
import Requester from "@/requester";

import { UserCreateFormData } from "../../../../back/src/types/formData";
import { Laboratorio } from "../../../../back/src/models/Laboratorio";
import { Papel } from "../../../../back/src/models/Papel";

export default Vue.extend({
  name: "AuthLogin",
  data: () => ({
    loading: {
      submitForm: false,
      carregaDados: false,
    },
    laboratorios: [] as Laboratorio[],
    papeis: [] as Papel[],
    showPassword: false,
    form: {
      nome: null,
      email: null,
      email2: null,
      senha: null,
      senha2: null,
      is_admin: false,
      laboratorio_id: null,
      papel_id: null,
    },
    rules: {
      required: [(v: string) => !!v || "Campo obrigatório"],
    },
  }),
  computed: {
    appendIconPassword() {
      return this.showPassword ? "mdi-eye-off" : "mdi-eye";
    },
    formUserCreate(): Vue & { validate: () => boolean } {
      return this.$refs.formUserCreate as Vue & {
        validate: () => boolean;
      };
    },
    rulesPassword() {
      if (this.form.senha2) {
        return [
          (v: string) => v === this.form.senha || "Senhas diferentes",
          ...this.rules.required,
        ];
      }
      return this.rules.required;
    },
    rulesEmail() {
      if (this.form.email2) {
        return [
          (v: string) => v === this.form.email || "E-mails diferentes",
          ...this.rules.required,
        ];
      }
      return this.rules.required;
    },
  },
  mounted() {
    this.carregaDados();
  },
  methods: {
    async carregaDados() {
      this.loading.carregaDados = true;
      try {
        this.laboratorios = await Requester.laboratorio.public.recuperaLaboratorios();
        this.papeis = await Requester.users.public.buscaPepeis();
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.carregaDados = false;
      }
    },

    submitFormDebounce: _.debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.formUserCreate.validate()) {
        return;
      }
      this.loading.submitForm = true;

      const formData: UserCreateFormData = {
        nome: this.form.nome,
        email: this.form.email,
        senha: this.form.senha,
        is_admin: this.form.is_admin,
        papel_id: this.form.papel_id,
        laboratorio_id: this.form.laboratorio_id,
      };

      try {
        await Requester.users.public.createUser(formData);
        this.$root.$emit("showToast", {
          text: "Usuário criado com sucesso, por gentileza confirme seu email!",
          color: "success",
        });
        this.$router.push({ name: "Login" });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
  watch: {
    "form.is_admin": {
      handler() {
        if (this.form.is_admin) {
          this.form.laboratorio_id = null;
          this.form.papel_id = null;
        }
      },
    },
  },
});
